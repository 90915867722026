#footer {
    position: fixed;
    left: 0;
    bottom: -100px; /* Start off the screen */
    width: 100%;
    background-color: #5BA485; /* Your brand color */
    color: white;
    text-align: center;
    transition: bottom 0.5s ease-in-out;
    z-index: 1000; /* Ensure the footer is above other content */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 10px;
    text-align: center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

#footer:hover {
    bottom: 0; /* Slide up when hovered */
}

.footer-left, .footer-right {
    flex: 1;
}

.footer-left {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.footer-content {
    flex: 1;
    text-align: center;
}

.social-media-icons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.social-media-icons svg {
    margin: 0 5px;
    font-size: 24px; /* Adjust size as needed */
    cursor: pointer;
}

.social-media-icons svg:hover {
    color: black;
}

.footer-image {
    width: 100px; /* Adjust size as needed */
    height: auto;
}

@media screen and (min-width: 200px) and (max-width: 450px) { 
    .footer-content {
        padding-left: 15px;
        padding-right: 15px;
    }
}