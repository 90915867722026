* {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
        Helvetica, Arial, "Lucida Grande", sans-serif;
}

.parallax-page .parallax-section {
    height: 100vh;
    position: relative;
    background-attachment: scroll;
    /* background-position: center; */
    background-repeat: no-repeat;
    /* background-size: cover; */
    transition: background-image 0.5s ease-in-out;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;

    background-size: cover;
    /* or a specific size, e.g., '100% auto' */
    background-position: center;
    /* Adjust as needed */
}

.logo {
    padding-top: 50px;
    width: 600px;
}

.scroll-to-learn {
    padding-top: 20px;
}

.down-arrow {
    font-family: Arial, sans-serif;
    /* Fallback to a font that supports the characters */
    font-size: 30px;
}

.parallax-page .parallax-section .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    /* Dark overlay for text readability */
    opacity: 0;
    transition: opacity 0.5s ease;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

.content-area {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    align-items: center;
    justify-items: center;
}

.bubble {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 15px;
    /* Rounded corners */
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.bubble:hover {
    transform: scale(1.05);
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
}

.bubble-primary {
    background: linear-gradient(135deg, #6dd5ed, #2193b0);
    color: white;
}

.bubble-secondary {
    background: linear-gradient(135deg, #ff6e7f, #bfe9ff);
    color: white;
}

.parallax-page .parallax-section .overlay .bubble {
    background-color: white;
    border-radius: 50%;
    /* Circular bubbles */
    width: 150px;
    /* Adjust size as needed */
    height: 150px;
    /* Adjust size as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
    margin: 20px;
    transition: transform 0.3s ease;
}

.non-parallax-section {
    width: 100%;
    border-radius: 25px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.non-parallax-content-area {
    padding-left: 25vw;
    padding-right: 25vw;
    padding-top: 50px;
    padding-bottom: 50px;
}

.parallax-page .parallax-section .overlay .bubble:hover {
    transform: scale(1.1);
    /* Slight zoom on hover */
}

.parallax-page .parallax-section:hover .overlay {
    opacity: 1;
}

.for-business {
    background-image: url('./assets/landing-image1-min.jpg');
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    color: azure;
    padding-top: 100px;
    padding-bottom: 100px;
}

.our-courses {
    background-image: url('./assets/landing-image3-min.jpg');
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    color: azure;
}

.funded-by-dfe {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding-top: 100px;
    padding-bottom: 100px;
}

.table-div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding-bottom: 30px;
}

.funded-by-dfe table {
    border-collapse: collapse;
}

.funded-by-dfe table,
th,
td {
    border: 1px solid black;
}

.funded-by-dfe th,
td {
    padding: 10px;
    text-align: left;
}

.funded-by-dfe th {
    background-color: #5ba4862d;
}

.why-source-coding {
    background-image: url('./assets/landing-image2-min.jpg');
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    color: azure;
}

.why-source-coding-content {
    padding-left: 25vw;
    padding-right: 25vw;
    padding-top: 50px;
    padding-bottom: 50px;
    color: azure;
}

.why-source-coding-content h2 {
    padding-top: 30px;
}

.content-section {
    padding: 40px;
    text-align: center;
}

.modern-footer {
    background-color: #5BA485;
    /* Your brand color */
    color: white;
    padding: 20px;
    text-align: center;
}

.modern-button {
    background-color: #5BA485;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    outline: none;
    margin-top: 25px;
}

.modern-button:hover {
    background-color: darken(#5BA485, 10%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.modern-button:active {
    background-color: lighten(#5BA485, 10%);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.card-container {
    display: flex;
    justify-content: space-around;
    padding: 20px;
}

.card {
    flex-basis: 30%;
    /* Adjust based on container width */
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    /* Stack children vertically */
}

.card-header {
    background-color: #5BA485;
    color: white;
    padding: 10px;
    text-align: center;
    font-size: 18px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card-body {
    padding: 15px;
    text-align: center;
    flex-grow: 1;
    /* Allow the body to grow and fill available space */
    display: flex;
    flex-direction: column;
    /* Stack children vertically */
    justify-content: space-between;
    /* Space between content and button */
    color: black;
}

.card-button {
    background-color: #5BA485;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    align-self: center;
    /* Center button horizontally */
    margin-top: 10px;
    /* Add space above the button */
}

.card-button:hover {
    background-color: darken(#5BA485, 10%);
}

.text-panel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    /* Semi-transparent white background */
    color: #333;
    /* Dark text for readability */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    max-width: 100%;
    /* Adjust as necessary */
    text-align: center;
}

.text-panel h2 {
    margin-top: 0;
    color: #5BA485;
    /* Optional: use your brand color for headings */
}

.text-panel p {
    font-size: 16px;
    line-height: 1.6;
}

.form-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 20px;
}

.contact-form {
    width: 100%;
    max-width: 500px;
    /* Adjust as needed */
    background-color: rgba(255, 255, 255, 0.9);
    /* Semi-transparent white */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding-bottom: 100px;
}

.contact-form label {
    display: block;
    margin-top: 10px;
    color: #333;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form select,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    /* Added for consistent sizing */
}

.contact-form textarea {
    height: 100px;
    /* Adjust as needed */
    resize: vertical;
    /* Allow vertical resizing */
}

.submit-button {
    background-color: #5BA485;
    /* Your brand color */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: darken(#5BA485, 10%);
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    position: relative;
    padding: 10px;
    background: white;
    border-radius: 10px;

    background: rgba(255, 255, 255, 0.50);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.popup-content img {
    /* height: 60vh; */
    width: 60vw;
}

.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    background: none;
    font-size: 20px;
    font-weight: 900;
    cursor: pointer;
}

.download-button {
    background-color: #5BA485; /* Adjust color as needed */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none; /* Remove underline from link */
    display: inline-block; /* Align with other buttons */
    margin-top: 10px; /* Space from the previous button */
}



@media screen and (min-width: 1150px) and (max-width: 1450px) {

    /* Styles for screens between 1000px and 1450px */
    .text-panel {
        width: 70%;
    }

    .text-panel h1 {
        font-size: 26px;
    }

    .text-panel h2 {
        font-size: 22px;
    }

    .text-panel p {
        font-size: 14px;
    }

    .parallax-page .parallax-section {
        height: 100vh;
        position: relative;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: background-image 0.5s ease-in-out;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
    }

    .form-parent {
        width: 60%;
    }
    
    .popup-content img {
        width: 60%;
    }
}

@media screen and (min-width: 1150px) {

    /* Styles for screens larger than 1450px */
    .text-panel {
        width: 60%;
    }

    .parallax-page .parallax-section {
        height: 100vh;
        position: relative;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: background-image 0.5s ease-in-out;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
    }

    .popup-content img {
        width: 70%;
    }
}


@media screen and (min-width: 650px) and (max-width: 1150px) {

    /* Styles for screens between 500px and 1000px */
    .text-panel {
        width: 80%;
    }

    .text-panel h1 {
        font-size: 22px;
    }

    .text-panel h2 {
        font-size: 18px;
    }

    .text-panel p {
        font-size: 14px;
        line-height: 1.2;
    }

    .card-header {
        font-size: 16px;
        height: 60px;
    }

    .card-button {
        font-size: 16px;
    }

    .non-parallax-content-area {
        font-size: 14px;
    }

    .modern-button {
        font-size: 16px;
    }

    .logo {
        width: 450px;
    }

    .non-parallax-content-area {
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 10px;
        margin: 0 auto;
    }

    .form-parent {
        width: 80%;
    }

    .parallax-page .parallax-section {
        height: 100vh;
        position: relative;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: background-image 0.5s ease-in-out;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
    }

    .popup-content img {
        width: 90%;
    }
}

@media screen and (min-width: 450px) and (max-width: 650px) {

    /* Styles for screens between 500px and 1000px */
    .text-panel {
        width: 80%;
    }

    .text-panel h1 {
        font-size: 20px;
    }

    .text-panel h2 {
        font-size: 16px;
    }

    .text-panel p {
        font-size: 12px;
        line-height: 1.2;
    }

    .card-header {
        font-size: 14px;
        height: 70px;
    }

    .card-button {
        font-size: 14px;
    }

    .non-parallax-content-area {
        font-size: 12px;
    }

    .modern-button {
        font-size: 14px;
    }

    .logo {
        width: 400px;
    }

    .card-container {
        flex-direction: column;
    }

    .card2 {
        display: none;
    }

    .card3 {
        display: none;
    }

    .why-remove {
        display: none;
    }

    .non-parallax-content-area {
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 10px;
        margin: 0 auto;
    }

    .form-parent {
        width: 80%;
    }

    .popup-content img {
        width: 90%;
    }
}

@media screen and (min-width: 200px) and (max-width: 450px) {

    /* Styles for screens between 500px and 1000px */
    .text-panel {
        width: 85%;
    }

    .text-panel h1 {
        font-size: 18px;
    }

    .text-panel h2 {
        font-size: 16px;
    }

    .text-panel p {
        font-size: 12px;
        line-height: 1.2;
    }

    .card-header {
        font-size: 13px;
        height: 80px;
    }

    .card-button {
        font-size: 12px;
    }

    .non-parallax-content-area {
        font-size: 12px;
    }

    .modern-button {
        font-size: 12px;
    }

    .logo {
        width: 300px;
    }

    .card-container {
        flex-direction: column;
    }

    .card2 {
        display: none;
    }

    .card3 {
        display: none;
    }

    .why-remove {
        display: none;
    }

    .non-parallax-content-area {
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 10px;
        margin: 0 auto;
    }

    .form-parent {
        width: 80%;
    }

    .popup-content img {
        width: 90%;
    }
}

/* Disable hover effect and always show content on mobile devices */
@media screen and (max-width: 768px) {
    .parallax-page .parallax-section .overlay {
        opacity: 1;
        /* Always show overlay content */
    }
}

/* Desktop styles with hover effect */
@media screen and (min-width: 769px) {
    .parallax-page .parallax-section .overlay {
        opacity: 0;
        /* Hide overlay content by default */
        transition: opacity 0.5s ease;
        /* Smooth transition for hover effect */
    }

    .parallax-page .parallax-section:hover .overlay {
        opacity: 1;
        /* Show content on hover */
    }

}