nav {
    background-color: white;
    position: fixed;
    top: 20px;
    /* Adjust as needed for positioning */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    /* Ensure the navbar is above other content */
    border-radius: 20px;
    /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    padding: 10px 20px;
    /* Add some padding inside the nav */
    display: flex;
    /* Align items in a row */
    justify-content: center;
    /* Center items horizontally */
    align-items: center;
    /* Center items vertically */
}

.nav-logo {
    height: 45px;
    padding-top: 5px;
    padding-bottom: 5px;
    /* Adjust as needed */
    margin-right: 20px;
    /* Space between the logo and the menu */
}

nav ul {
    list-style: none;
    /* Remove default list styling */
    padding: 0;
    /* Remove default padding */
    margin: 0;
    /* Remove default margin */
    display: flex;
    /* Align items in a row */
    align-items: center;
    /* Center items vertically */
    justify-content: center;
    /* Center items horizontally */
}

nav ul li {
    margin: 0 10px;
    /* Spacing between menu items */
}

nav ul li a {
    text-decoration: none;
    /* Remove underline from links */
    color: #333;
    /* Color for the link text */
    font-weight: bold;
    /* Make the text bold */
}

/* Optional: Style for hover effect on links */
nav ul li a:hover {
    color: darken(#5BA485, 20%);
}

.login-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 26px;
}

.apply-now {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.apply-button {
    background-color: #5BA485;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 900;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    outline: none;
    margin-left: 15px;
}

.apply-button:hover {
    background-color: darken(#5BA485, 20%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.apply-button:active {
    background-color: lighten(#5BA485, 20%);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}


@media screen and (min-width: 750px) and (max-width: 850px) {

    /* Styles for screens between 500px and 1000px */
    nav {
        font-size: 14px;
    }

    .apply-button {
        font-size: 12px
    }

    .nav-logo {
        height: 35px; /* Adjust as needed */
        margin-right: 15px; /* Space between the logo and the menu */
      }
}

.burger-menu {
    display: none; /* Hide burger menu by default */
    cursor: pointer;
}

.burger-line {
    width: 25px;
    height: 3px;
    background-color: #333; /* Burger line color */
    margin: 5px;
    transition: all 0.3s ease;
}

@media screen and (max-width: 750px) {
    .burger-menu {
        display: block; /* Show burger menu */
    }

    .nav {
        left: 85%;
    }

    .nav-logo {
        display: none; /* Hide logo */
    }

    .nav-links {
        display: none;
        position: absolute;
        right: 0;
        top: 60px; /* Adjust based on your navbar's height */
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        width: 100%; /* Full width */
        background-color: #fff; /* Background color for the dropdown */
        border-radius: 20px;
        font-size: 14px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 30px;
    }

    .nav-links.expanded {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        gap: 10px;
    }

    .nav-links li {
        margin-bottom: 10px;
    }

    .apply-button {
        font-size: 12px;
        border-radius: 20px;
    }
}